<template>
    <Section
        class="rich-text-container"
        width="sm"
        :class="data.slice_label"
    >
        <div
            v-for="(section, index) in data.items"
            :key="index"
            class="iterator"
        >
            <component
                :is="sliceType(section.content_layout)"
                v-if="section.content_layout"
                :data="section"
            />

            <Normal
                v-else-if="!section.content_layout"
                :data="section"
            />

            <div
                v-if="section.page_link.id"
                class="rich-text--button"
            >
                <ButtonNuxt
                    :link="section.page_link"
                    :text="section.page_link_text"
                    background="green"
                    size="md"
                />
            </div>
        </div>
    </Section>
</template>

<script>
import VideoContainer from '@/components/slices/rich-text/RichTextVideo.vue';
import HighlightBox from '@/components/slices/rich-text/RichTextHighlightBox.vue';
import Quote from '@/components/slices/rich-text/RichTextQuote.vue';
import Normal from '@/components/slices/rich-text/RichTextNormal.vue';
export default {
    components: { VideoContainer, HighlightBox, Quote, Normal },
    props: {
        data: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    methods: {
        sliceType(type) {
            // NOTE: Exception due to built-in component name: video
            if (type === 'video') {
                return 'VideoContainer';
            }

            else if (type.includes('_')) {
                const split = type.split('_');
                for (let i = 0; i < split.length; i++) {
                    split[i] = split[i].charAt(0).toUpperCase() + split[i].slice(1);
                }
                return split.join('');
            }
            return type;
        }
    }
};
</script>

<style lang="scss" scoped>
    .rich-text-container::v-deep {

        .iterator {
            margin-bottom: 30px;
            width: 100%;

            @include device(pad) {
                margin-bottom: 60px;
            }

            h2:first-child { margin-top: 0!important; }
            p:last-child { margin-bottom: 0; }
        }

        .iterator:last-child { margin-bottom: 0; }

        .video {
            @include device(pad) {
                margin-top: -20px;
                margin-bottom: -20px;
            }
        }

        .rich-text--button {
            @include flex(start);
            text-align: left;
            padding-top: 20px;
            @include spacing(margin, 8, top);
            width: 100%;
            border-top: 2px solid rgba(0,0,0,.03);
        }

    }
</style>
