<template>
    <tr>
        <td>{{ period.amortization }}</td>
        <td>{{ period.monthlyPayment }}</td>
        <td>{{ period.interest }}</td>
        <td>{{ period.fees }}</td>
        <td>{{ period.payback }}</td>
        <td>{{ period.paybackAndInterestBack }}</td>
    </tr>
</template>

<script>
export default {
    props: {
        period: {
            type: Object,
            required: true,
        },
    },
};
</script>
