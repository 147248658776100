<template>
    <Section
        id="recension-och-kontaktuppgifter"
        width="md"
    >
        <div class="row list-styling">
            <div class="row-inner">
                <h2>{{ $translate('lp_pros_h2', 'Fördelar') }}</h2>
                <ul class="list-icon list-checkmark">
                    <li
                        v-for="(iterator, index) in document.data.pros"
                        :key="index"
                    >
                        {{ iterator.pro }}
                    </li>
                </ul>
            </div>
            <div class="row-inner">
                <h2>{{ $translate('lp_cons_h2', 'Nackdelar') }}</h2>
                <ul class="list-icon list-x-mark">
                    <li
                        v-for="(iterator, index) in document.data.cons"
                        :key="index"
                    >
                        {{ iterator.con }}
                    </li>
                </ul>
            </div>
        </div>

        <div class="row">
            <div class="row-inner grid">
                <div class="row-inner__container">
                    <h2>
                        {{ 
                            $translate('lp_about_h2', 'Om {title}')
                                .replace('{title}', document.data.title) 
                        }}
                    </h2>
                    <div
                        class="rich-text"
                        v-html="$prismic.asHtml(document.data.about_lender)"
                    />
                </div>

                <div class="row-inner__container">
                    <h2>{{ $translate('lp_loan_terms_h2', 'Lånevillkor') }}</h2>
                    <div
                        class="rich-text"
                        v-html="$prismic.asHtml(document.data.loan_terms)"
                    />
                </div>

                <div class="row-inner__container">
                    <h2>{{ $translate('lp_how_to_apply_h2', 'Hur du ansöker') }}</h2>
                    <div
                        class="rich-text checkmark"
                        v-html="$prismic.asHtml(document.data.how_to_apply)"
                    />
                </div>

                <div class="row-inner__container">
                    <h2>{{ $translate('lp_service_and_security_h2', 'Service & Säkerhet') }}</h2>
                    <div
                        class="rich-text checkmark"
                        v-html="$prismic.asHtml(document.data.service_security)"
                    />
                </div>

                <div class="row-inner__container">
                    <h2>{{ $translate('lp_summary_h2', 'Sammanfattning') }}</h2>
                    <div
                        class="rich-text checkmark"
                        v-html="$prismic.asHtml(document.data.summary)"
                    />
                </div>

                <div v-if="hasFaq" id="faq" class="row-inner__container"> 
                    <h2 v-if="$validateText(document.data.faq_h2)">
                        {{ $prismic.asText(document.data.faq_h2) }}
                    </h2>
                    <faq-layout :faq="document.data.faq_items" />
                </div>
            </div>

            <div class="row-inner">
                <div class="row-inner__container">
                    <h2 class="head exception">
                        {{ $translate('company_opening_hours_h2', 'Öppettider') }}

                        <span
                            v-if="checkIsOpen"
                            class="open"
                        >
                            {{ $translate('company_open', 'Öppet nu') }}
                        </span>
                        <span
                            v-else
                            class="closed"
                        >
                            {{ $translate('company_closed', 'Stängt') }}
                        </span>
                    </h2>

                    <ul class="regular">
                        <li
                            v-for="(iterator, index) in openingHours"
                            :key="index"
                        >
                            <span>{{ iterator.name }}</span>
                            <span>{{ iterator.from }} - {{ iterator.to }}</span>
                        </li>
                    </ul>
                </div>

                <div class="row-inner__container">
                    <h2>{{ $translate('company_details_h2', 'Företagsuppgifter') }}</h2>
                    <ul class="regular">
                        <li
                            v-for="(iterator, index) in companyInfo"
                            :key="index"
                        >
                            <span>{{ iterator.name }}</span>
                            <span>{{ iterator.value }}</span>
                        </li>
                    </ul>
                </div>

                <div
                    v-if="$helpers.filterEmptyRelations(document.data.similar_loans)"
                    class="row-inner__container"
                >
                    <h2 class="head">{{ $translate('lp_similar_loans_h2', 'Liknande lån') }}</h2>
                    <ul class="similar-loans">
                        <SimilarLoans
                            v-for="(iterator, index) in document.data.similar_loans"
                            :id="iterator.lender.id"
                            :key="index"
                        />
                    </ul>
                </div>

                <div v-if="listDocumentData.length">
                    <div 
                        v-for="(data, index) in listDocumentData" 
                        :key="index"
                        class="row-inner__container"
                    >
                        <h2 class="head">
                            {{ data.title }}
                        </h2>
                        <ListDocuments
                            v-for="doc in data.documents"
                            :key="doc.id"
                            :document="doc"
                        />
                    </div>
                </div>
            </div>
        </div>
    </Section>
</template>

<script>
import ListDocuments from '@/components/ListDocuments.vue';
import FaqLayout from '@/components/FaqLayout.vue';
import { replace } from '@swegaming-ab/nuxtjs-helpers';
export default {
    components: { ListDocuments, FaqLayout },
    props: {
        document: {
            type: Object,
            required: true,
            default: () => {}
        },
        model: {
            type: Object,
            required: true,
            default: () => {}
        },
        hasFaq: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            time: {
                day: undefined,
                hour: undefined
            },
            isOpen: false,
            fetchData: {
                relatedArticles: {},
                comparisonPages: {}
            }
        };
    },
    async fetch() {
        const comparisonPagesPredicates = [
            this.$prismic.predicates.at('document.type', 'page'),
            this.$prismic.predicates.at('document.tags', [this.prismicTags])
        ];

        const articlesPredicates = [
            this.$prismic.predicates.at('document.type', 'post'),
            this.$prismic.predicates.fulltext('document', this.document.data.title.toLowerCase())
        ];

        const articlesResponse = await this.$prismic.api.query( 
            articlesPredicates, 
            { lang: process.env.PRISMIC_LOCALE }
        );
        const comparisonPagesResponse = await this.$prismic.api.query( 
            comparisonPagesPredicates, 
            { lang: process.env.PRISMIC_LOCALE }
        );

        this.fetchData.comparisonPages = replace(comparisonPagesResponse.results);
        this.fetchData.relatedArticles = replace(articlesResponse.results);
    },
    fetchKey() {
        return 'lender-review-' + this.document.id;
    },
    computed: {
        listDocumentData() {
            return [
                {
                    validation: this.showRelatedArticles,
                    title: this.$translate('lp_related_articles_h2', 'Relaterade artiklar'),
                    documents: this.fetchData.relatedArticles
                },
                {
                    validation: this.showComparisonPages,
                    title: this.$translate('lp_our_comparison_h2', 'Våra jämförelser'),
                    documents: this.fetchData.comparisonPages
                }
            ].filter(item => item.validation);
        },
        prismicTags() {
            return this.document.data.comparison_tag ?? '';
        },
        checkIsOpen() {
            let date = new Date();
            this.time.hour = date.getHours();
            this.time.day = date.getDay();

            for (let i = 0; i < this.openingHours.length; i++) {
                if (
                    this.openingHours[i].day === this.time.day &&
                    this.time.hour >= this.openingHours[i].from.split(':')[0] &&
                    this.time.hour <= this.openingHours[i].to.split(':')[0]
                )
                return true;
            }
        },

        showRelatedArticles() {
            return this.fetchData.relatedArticles.length > 0;
        },
        showComparisonPages() {
            return this.fetchData.comparisonPages.length > 0;
        },
        openingHours() {
            return [
                {
                    name: this.$translate('monday', 'Måndag'),
                    day: 1,
                    from: this.model._data.opening_hours_monday_open,
                    to: this.model._data.opening_hours_monday_close
                },
                {
                    name: this.$translate('tuesday', 'Tisdag'),
                    day: 2,
                    from: this.model._data.opening_hours_tuesday_open,
                    to: this.model._data.opening_hours_tuesday_close
                },
                {
                    name: this.$translate('wednesday', 'Onsdag'),
                    day: 3,
                    from: this.model._data.opening_hours_wednesday_open,
                    to: this.model._data.opening_hours_wednesday_close
                },
                {
                    name: this.$translate('thursday', 'Torsdag'),
                    day: 4,
                    from: this.model._data.opening_hours_thursday_open,
                    to: this.model._data.opening_hours_thursday_close
                },
                {
                    name: this.$translate('friday', 'Fredag'),
                    day: 5,
                    from: this.model._data.opening_hours_friday_open,
                    to: this.model._data.opening_hours_friday_close
                },
                {
                    name: this.$translate('saturday', 'Lördag'),
                    day: 6,
                    from: this.model._data.opening_hours_saturday_open,
                    to: this.model._data.opening_hours_saturday_close
                },
                {
                    name: this.$translate('sunday', 'Söndag'),
                    day: 7,
                    from: this.model._data.opening_hours_sunday_open,
                    to: this.model._data.opening_hours_sunday_close
                }
            ];
        },

        companyInfo() {
            return [
                {
                    name: this.$translate('company_name', 'Företagsnamn'),
                    value: this.model._data.company_name
                },
                {
                    name: this.$translate('company_org_nr', 'Org.nummer'),
                    value: this.model._data.company_registration_number
                },
                {
                    name: this.$translate('company_address', 'Adress'),
                    value: this.model._data.company_addres
                },
                {
                    name: this.$translate('company_postal_county', 'Postort'),
                    value: this.model._data.company_postal_number + ' ' + this.model._data.company_county
                },
                {
                    name: this.$translate('company_phone', 'Telefon'),
                    value: this.model._data.company_phone
                },
                {
                    name: this.$translate('company_email', 'E-post'),
                    value: this.model._data.company_email
                }
            ];
        }
    }
};
</script>

<style lang="scss" scoped>
section::v-deep {
    .section-inner {
        @include grid(1, 40px);

        @include device(pad) {
            @include grid(1, 60px);
        }

        .row {
            @include grid(1, 40px);

            @include device(pad) {
                @include grid(2, 80px);
            }

            &-inner.grid {
                @include grid(1, 40px);

                @include device(pad) {
                    @include grid(1, 60px);
                }

                .row-inner__container { margin: 0; }
            }
            &-inner {

                &__container {
                    margin-bottom: 60px;
                }

                h2 {
                    @include font-size(24px);
                    display: block;
                    font-family: $fontHeader;
                    text-align: left;
                    color: $green;
                    border-bottom: 2px solid rgba(0,0,0,.03);
                    padding-bottom: 20px;
                    margin-bottom: 20px;

                    @include device(pad) {
                        @include font-size(28px);
                    }
                }

                .head.exception {
                    @include flex(between);
                    .open {
                        color: $green;
                        border-color: $green;
                    }
                    .closed {
                        color: #999;
                        border-color: #999;
                    }
                    .open,
                    .closed {
                        border: 2px solid;
                        border-radius: 25px;
                        font-size: 12px;
                        padding: 5px 20px;
                        font-weight: bold;
                        font-family: $fontText;
                        line-height: 16px;
                    }
                }

                ul.list-icon {
                    @include grid(1, 20px);
                }

                ul.regular {
                    @include grid(1, 0);

                    li {
                        @include flex(between);
                        @include spacing(padding, 2);
                        &:nth-child(odd) { background: #fafafa; }

                        span:last-child { font-family: $fontHeader; }
                    }
                }
            }
        }
    }
}
</style>
