<template>
    <Section 
        v-if="$validateText(quote)"
        width="sm"
    >
        <div 
            v-if="$validateText(data.primary.text)"
            class="rich-text teaser-text" 
            v-html="$prismic.asHtml(data.primary.text)"    
        />
        <div class="quote-wrapper" :class="textAlignment">
            <i class="fa-solid fa-quote-right quote-icon" />
            <blockquote>
                <div 
                    class="quote rich-text" 
                    :class="quoteClass" 
                    v-html="$prismic.asHtml(quote)" 
                />
                <div 
                    v-if="$validateText(quoteBy)"
                    class="quote-by-wrapper" 
                    :class="fontSizeAuthor"
                >
                    <i class="fa-regular fa-hyphen" />
                    <div class="quote-by rich-text" v-html="$prismic.asHtml(quoteBy)" />
                </div>
            </blockquote>
            <i class="fa-solid fa-quote-left quote-icon" />
        </div>
    </Section>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    computed: {
        quote() {
            return this.data.primary.quote;
        },
        quoteTextLength() {
            return this.$prismic.asText(this.quote).length;
        },
        quoteBy() {
            return this.data.primary.quote_by;
        },
        textAlignment() {
            if (this.data.primary.align_text === 'left') {
                return '';
            }
            return 'text-center';
        },
        quoteClass() { 
            if (this.quoteTextLength < 60) {
                return 'h2';
            } 
            if (this.quoteTextLength < 250) {
                return 'h3';
            } 
            return 'h5';
        },
        fontSizeAuthor() {
            if (this.quoteTextLength < 250) {
                return 'font-size-large';
            } 
            return 'font-size-small';
        }
    }
};
</script>
<style lang='scss' scoped>
    .teaser-text {
        margin-bottom: 30px;
    }
    .quote-wrapper {
        display: flex;
        flex-direction: column;
        @include device(pad) {
            flex-direction: row;
        }
        &.text-center {
            text-align: center;
        }
        .quote {
            font-weight: bold;
            margin: 0;
            ::v-deep {
                p {
                    font-size: inherit;
                    line-height: inherit;
                    margin-bottom: 0;
                    &:first-child {
                        margin-top: 0;
                    }
                }
            }
        }
        blockquote {
            width: 100%;
            padding: 1rem 0;
            @include device(pad) {
                padding: 4rem 0;
            }
        }
        .quote-by-wrapper {
            font-style: italic;
            color: #999;
            margin-top: 1.5rem;
            &.font-size-large {
                font-size: 1.5rem;
            }
            &.font-size-small {
                font-size: 1.25rem;
            }
            .quote-by ::v-deep {
                display: inline;
                p {
                    font-size: inherit;
                    display: inline;
                }
            }
        }
        .quote-icon {
            font-size: 3rem;
            color: #999;
        }
        i.fa-quote-right {
            align-self: flex-start;
        }
        i.fa-quote-left {
            align-self: flex-end;
        }
    }
</style>