<template>
    <Section 
        id="connected-banks-table" 
        :width="sectionWidth" 
        class="connected-banks-table"
    >
        <div
            v-if="$validateText(data.primary.header)"
            class="rich-text"
            v-html="$prismic.asHtml(data.primary.header)"
        />
        <div
            v-if="$validateText(data.primary.teaser)"
            class="rich-text"
            v-html="$prismic.asHtml(data.primary.teaser)"
        />
        <div class="searchbar">
            <input
                v-model="search"
                type="text"
                :placeholder="$translate('search_for_bank', 'Sök efter bank...')"
            >
        </div>
        <div class="table-container wrapper">
            <table class="table">
                <thead>
                    <tr>
                        <th class="header-text">
                            {{ $translate('bank', 'Bank') }}
                        </th>
                        <th v-for="lender in lenders" :key="lender.id">
                            <nuxt-link :to="$prismic.linkResolver(lender)" class="highlighted-link">
                                <PrismicImage
                                    :img="lender.data.image"
                                    h="30"
                                    :alt="lender.data.title"
                                />
                            </nuxt-link>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="bank in allConnectedBanks"
                        v-show="visibleConnectedBanks.includes(bank)"
                        :key="bank.id"
                    >
                        <td>
                            <div class="flex-row">
                                <nuxt-link :to="$prismic.linkResolver(bank)">
                                    <PrismicImage
                                        :img="bank.data.icon"
                                        :alt="bank.data.title"
                                        w="42"
                                        class="bank-logo"
                                    />
                                </nuxt-link>

                                <div class="bank-name flex-column">
                                    <nuxt-link
                                        :to="$prismic.linkResolver(bank)"
                                        class="highlighted-link"
                                    >
                                        <span>{{ bank.data.title }}</span>
                                    </nuxt-link>
                                    <p class="interest-rate">
                                        {{ $translate('interest', 'Ränta') }}:
                                        {{ getInterestRate(bank) }}
                                    </p>
                                </div>
                            </div>
                        </td>

                        <td v-for="lender in lenders" :key="lender.id">
                            <i class="icon-lg" :class="$formatBooleanIcon(hasBank(lender, bank))" />
                        </td>
                    </tr>
                    <tr v-if="visibleConnectedBanks.length > numBanksToShow - 1">
                        <td />
                        <td
                            :colspan="lenders.length"
                            class="show-more"
                            @click="showAllBanks = !showAllBanks"
                        >
                            {{
                                showAllBanks
                                    ? $translate('show_less', 'Visa mindre')
                                    : $translate('show_all', 'Visa alla')
                            }}

                            <i
                                class="fas chevron"
                                :class="showAllBanks ? 'fa-chevron-up' : 'fa-chevron-down'"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td />

                        <td v-for="(lender, index) in lenders" :key="lender.id">
                            <ButtonTarget
                                class="to-application"
                                :link="lender"
                                :text="applyText(lender)"
                                background="green"
                                size="md"
                                placement="connected-banks-table"
                                :position="index + 1"
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </Section>
</template>

<script>
import { orderBy, flatten } from 'lodash';

export default {
    props: {
        data: {
            type: Object,
            required: true,
            default: () => {},
        },
    },
    data() {
        return {
            showAllBanks: false,
            numBanksToShow: 10,
            search: '',
        };
    },
    computed: {
        lenders() {
            return this.data.items
                .map(item => this.$store.state.lenders[item.lender.id]);
        },
        sectionWidth() {
            return this.data.primary.section_width || 'lg';
        },
        allConnectedBanks() {
            const allConnectedBanks = flatten(
                this.lenders
                    .map(lender => lender.data.connected_banks)
            ).filter(item => item.lender.id);
            
            const uniqueConnectedBanks = allConnectedBanks
                .filter((bank, index, self) => {
                    return self.findIndex(b => b.lender.id === bank.lender.id) === index;
                });

            const populated = uniqueConnectedBanks.map(
                bank => this.$store.state.lenders[bank.lender.id]
            );

            return orderBy(populated, 'data.title', 'asc');
        },
        visibleConnectedBanks() {
            const search = this.search.toLowerCase();
            return this.allConnectedBanks
                .filter(bank => bank.data.title.toLowerCase().includes(search))
                .slice(0, this.showAllBanks ? this.allConnectedBanks.length : this.numBanksToShow);
        },
    },
    methods: {
        hasBank(lender, bank) {
            const lenderIds = lender.data.connected_banks.map(
                connectedBank => connectedBank.lender.id
            );

            return lenderIds.includes(bank.id);
        },
        getInterestRate(bank) {
            const model = new this.$models.Loan(bank);
            return model.effectiveInterestString;
        },
        applyText(lender) {
            const lenderBankCount = lender.data.connected_banks.length;
            return this.$translate('cta_apply_for_banks', 'Ansök på {count} banker').replace(
                '{count}',
                lenderBankCount
            );
        },
    }
};
</script>

<style lang="scss" scoped>
.table {
    th,
    td {
        padding: 1em 1em 1em 1.5em;
    }

    th {
        background: $lightgray;
    }

    tr {
        background-color: $white;
        td:first-child {
            background-color: $white;
        }
        &:nth-child(even) {
            background-color: $lightgray;
            td:first-child {
                background-color: $lightgray;
            }
        }

        &:last-child {
            td:first-child {
                background-color: $white;
            }
        }

        .show-more {
            color: $green;
            text-decoration: none;
            @include font-size(18px, 12px);
            font-weight: bold;

            &:hover {
                cursor: pointer;
            }

            padding: 0.8em;
            padding-left: 410px;

            @media (max-width: 1024px) {
                padding-left: 200px;
            }

            @media (max-width: 768px) {
                padding-left: 80px;
            }
        }
        th,
        td {
            vertical-align: middle;
            text-align: left;
            @include border(right);

            &:first-child {
                box-shadow: 10px 0 5px -2px rgba(0, 0, 0, 0.04);
                -webkit-box-shadow: 10px 0 5px -2px rgba(0, 0, 0, 0.04);
                filter: drop-shadow(3px 0 6px rgba(0, 0, 0, 0.08));
                -webkit-filter: drop-shadow(3px 0 6px rgba(0, 0, 0, 0.06));

                position: -webkit-sticky;
                position: sticky;
                left: 0px;
                white-space: nowrap;
            }

            &:not(:first-child):not(.show-more) {
                text-align: center;
            }

            &:last-child {
                border: 0;
            }
        }

        &:nth-last-child(2) {
            background-color: white;
            td:first-child {
                background-color: $white;
            }
        }

        &:nth-last-child(2),
        &:last-child {
            td:first-child {
                filter: none;
                box-shadow: none;
            }
        }
    }

    .to-application {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .highlighted-link {
        color: $green;
        text-decoration: none;
        @include font-size(16px, 12px);

        &:hover {
            text-decoration: underline;
        }
    }

    .bank-logo {
        max-width: 42px;
        max-height: 42px;
        margin-right: 10px;
        border-radius: 20%;

        @media (max-width: 768px) {
            margin-right: 0 !important;
            max-width: 50px !important;
            max-height: 50px !important;
        }
    }

    .lender-logo {
        height: 30px;
        margin-right: 10px;
    }

    .flex-row {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
    }

    .flex-column {
        display: flex;
        flex-flow: column nowrap;
        align-items: left;
    }

    .icon-lg {
        font-size: 20px;
    }

    .chevron {
        margin-left: 4px;
    }

    .header-text {
        font-size: 1em;
    }
}

.interest-rate {
    @include font-size(12px, 8px);
}

.wrapper {
    position: relative;
    overflow-x: auto;
    white-space: nowrap;
}

.searchbar input {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    &:focus {
        border: 1px solid $green;
        outline: 1px solid $green;
    }
}

@media (max-width: 768px) {
    .table {
        th,
        td {
            padding: 0.85em;
        }

        th {
            font-size: 0.8em;
        }

        .highlighted-link {
            @include font-size(14px, 12px);
        }
    }

    .bank-name {
        display: none !important;
    }
}
</style>
