<template>
    <Section width="lg" class="section-compare-table">
        <div v-if="data.primary.h2 || data.primary.p" class="compare-lenders-padding">
            <h2 v-if="data.primary.h2" class="compare-lenders-title">
                {{ data.primary.h2 }}
            </h2>
            <div
                v-if="$validateText(data.primary.teaser)"
                class="rich-text"
                v-html="$prismic.asHtml(data.primary.teaser)"
            />
        </div>
        <div class="compare-table-scroll container table-container">
            <table class="compare-table">
                <tbody>
                    <tr>
                        <th />
                        <th v-for="(lender, index) in lenders" :key="index" class="company-logo">
                            <nuxt-link :to="$prismic.linkResolver(lender)">
                                <prismic-image w="100" :img="lender.data.image" />
                            </nuxt-link>
                        </th>
                    </tr>
                    <CompareLendersTableRow
                        v-for="row in lenderDataRows"
                        :key="row.keyPrefix"
                        :items="row.items"
                        :title="row.title"
                    />

                    <tr>
                        <th class="fw-bold">{{ $translate('to_application', 'Till ansökan') }}</th>
                        <td v-for="(lender, index) in lenders" :key="index">
                            <ButtonTarget
                                v-if="lender.data.redirect_enabled"
                                class="to-application"
                                :link="lender"
                                :text="$translate('to_application', 'Till ansökan')"
                                background="green"
                                size="md"
                                placement="comparison-table"
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </Section>
</template>
<script>
import CompareLendersTableRow from './CompareLendersTableRow.vue';

export default {
    components: { CompareLendersTableRow },
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    computed: {
        lenderDataRows() {
            // Return an array of objects with the lender data
            const rows = [this.amounts, this.durations, this.effectiveInterests];

            if (this.data.primary.table_type === 'small_loan') {
                rows.push(this.directPayouts, this.withoutUCs, this.paymentRemarks);
            } else if (this.data.primary.table_type === 'large_loan') {
                rows.push(this.setupFees);
            } else if (this.data.primary.table_type === 'broker') {
                rows.push(this.connectedBanks);
            }
            return rows;
        },
        lenders() {
            return this.data.items
                .map((iterator) => iterator.lender)
                .filter((lender) => lender.id)
                .map((lender) => this.$store.state.lenders[lender.id]);
        },
        models() {
            return this.lenders.map((lender) => new this.$models.Loan(lender));
        },
        amounts() {
            return {
                items: this.models.map((model) => model.amountString),
                title: this.$translate('amount', 'Belopp'),
            };
        },
        durations() {
            return {
                items: this.models.map((model) => model.getDurationString()),
                title: this.$translate('duration', 'Löptid'),
            };
        },
        effectiveInterests() {
            return {
                items: this.models.map((model) => model.effectiveInterestString),
                title: this.$translate('effective_interest', 'Effektiv ränta'),
            };
        },
        directPayouts() {
            return {
                items: this.models.map((model) => this.$formatBoolean(model.hasDirectPayments)),
                title: this.$translate('direct_payout', 'Direktutbetalning'),
            };
        },
        withoutUCs() {
            return {
                items: this.models.map((model) => this.$formatBoolean(!model.takesUC)),
                title: this.$translate('without_uc', 'Utan UC'),
            };
        },
        paymentRemarks() {
            return {
                items: this.models.map((model) => this.$formatBoolean(model.acceptsPaymentRemarks)),
                title: this.$translate('payment_remarks', 'Betalningsanmärkning'),
            };
        },
        setupFees() {
            return {
                items: this.models.map((model) => model.setupFeeString),
                title: this.$translate('setup_fee', 'Uppläggningsavgift'),
            };
        },
        connectedBanks() {
            return {
                items: this.models.map((model) => model.connectedBanks),
                title: this.$translate('connected_banks', 'Anslutna banker'),
            };
        },
    },
};
</script>
<style>
.to-application.btn-md {
    padding: 12px 16px !important;
}

tbody tr:nth-child(even) {
    background-color: #f8f8f8;
}

.compare-lenders-title {
    color: #5b8b36;
    margin: 0 0 1rem 0;
}

.section-compare-table {
    background-color: #f8f8f8;
}

.compare-lenders-padding {
    padding: 0 0 2rem 0;
}

.compare-table-scroll {
    overflow: auto;
}

table.compare-table {
    width: 100%;
    text-align: left;
    overflow: hidden;
}

.compare-table td {
    white-space: nowrap;
    padding: 1.5rem 1.5rem 1.5rem 0;
}

.compare-table tr {
    border-bottom: 2px solid rgba(0, 0, 0, 0.03);
}

.company-logo {
    vertical-align: middle !important;
    padding: 1.5rem 0 0.25rem 0 !important;
}

.fw-bold {
    white-space: nowrap;
    font-weight: bold;
    padding: 1.5rem 1rem 0.25rem 1rem !important;
}
</style>
