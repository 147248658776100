<template>
    <Section 
        v-if="lendersItems.length"
        width="sm" 
        class="trustpilot"
    >
        <div 
            v-if="$validateText(data.primary.text)"
            class="rich-text trustpilot-header" 
            v-html="$prismic.asHtml(data.primary.text)"    
        />

        <div class="table-container">
            <table class="table-container__table">
                <thead>
                    <tr>
                        <th>
                            {{ $translate('lender', 'Långivare') }}
                        </th>
                        <th>
                            {{ $translate('trustpilot_score', 'TrustScore') }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr 
                        v-for="(item, index) in lendersItems" 
                        :key="index"   
                    >
                        <td>
                            <div class="links">
                                <nuxt-link :to="$prismic.linkResolver(item.lender)">
                                    <prismic-image
                                        :img="item.lender.data.image"
                                        w="85"
                                    />
                                </nuxt-link>
                                <ButtonTarget 
                                    v-if="item.lender.data.redirect_enabled" 
                                    :link="item.lender" 
                                    :text="$translate('cta_to_application', 'Till ansökan')"
                                    background="green"
                                    class="lender-cta"
                                />
                            </div>
                        </td>
                        <td>
                            <div class="trustpilot-container">
                                <div class="trustpilot-data-wrapper">
                                    <div class="trustpilot-score-rating-wrapper">
                                        <span class="trustpilot-score">
                                            {{ item.trustpilotData.score }}
                                        </span>
                                        <div class="rating-wrapper">
                                            <i 
                                                v-for="(starIndex) in 5" 
                                                :key="starIndex"
                                                class="fa-sharp fa-solid fa-star fa-xs"
                                                :class="getStarClass(starIndex, item.trustpilotData.score)"
                                            />
                                        </div>
                                    </div>
                                    <span class="trustpilot-reviews-count">
                                        {{ 
                                            $translate('reviews_count', '{count} omdömen') 
                                                .replace('{count}', item.trustpilotData.count)
                                        }}
                                    </span>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </Section>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    computed: {
        lendersItems() {
            return this.data.items
                .filter(item => item.lender?.id)
                .map(item => {
                    const lender = this.$store.state.lenders[item.lender?.id];
                    const model = new this.$models.Loan(lender, this.$store);
                    return {
                        lender: lender,
                        trustpilotData: {
                            score: model?.trustpilotData?.score,
                            count: model?.trustpilotData?.ratingCount 
                        }
                    };
                })
                .filter(item => item.trustpilotData.score);
        }
    },
    methods: {
        getStarClass(starIndex, score) {
            if (starIndex <= Math.floor(score)) {
                return 'bg-success';
            }

            if (starIndex <= Math.round(score)) {
                return 'bg-half-success';
            }

            return '';
        }
    }
};
</script>

<style lang="scss" scoped>
    .trustpilot {
        .trustpilot-header {
            @include spacing(margin, 6, bottom);
        }

        tr {
            @include border(bottom);

            th {
                @include font-size(16px, 8px);
                &:first-child {
                    text-align: left;
                }
                &:last-child {
                    text-align: right;
                }
            }

            td {
                vertical-align: middle;

                .links {
                    display: flex;
                    flex-direction: column;
                    gap: 0.35rem;
                    width: fit-content;
                    text-decoration: none;

                    .lender-cta {
                        @include font-size(12px, 8px);
                    }
                }

                .trustpilot-container {
                    display: flex;
                    justify-content: flex-end;

                    
                    .trustpilot-data-wrapper {
                        display: flex;
                        flex-direction: column;
                        width: fit-content;
                        gap: 0.5rem;
    
                        .trustpilot-score-rating-wrapper {
                            display: flex;
                            justify-self: flex-start;
                            justify-content: center;
                            align-items: center;
                            gap: 0.5rem;
                            .rating-wrapper {
                                display: flex;
                                gap: 0.25rem;
                                align-items: center;
                
                                i {
                                    color: $white;
                                    background-color: $trustpilotGray;
                                    padding: 0.65rem 0.25rem;
                                    &.bg-success {
                                        background-color: $trustpilotGreen;
                                    }
                                    &.bg-half-success {
                                        background: linear-gradient(to left, $trustpilotGray 50%, $trustpilotGreen 50%);
                                    }
                                }
                            }
                
                            .trustpilot-score {
                                @include font-size(16px, 8px);
                                font-style: italic;
                                font-weight: bold;
                            }
                        }
    
                        .trustpilot-reviews-count {
                            text-align: right;
                            font-weight: lighter;
                        }
                    }
                }
            }
        }
    }
</style>