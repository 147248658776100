<template>
    <Section width="sm" class="cooperation-links">
        <div 
            v-if="$validateText(data.primary.header)"
            class="rich-text cooperation-links-header"
            v-html="$prismic.asHtml(data.primary.header)" 
        />
        <div class="cooperation-links-wrapper">
            <div 
                v-for="(item, index) in items" 
                :key="index"
                class="image-container"
            >
                <nuxt-link 
                    v-if="item.link.link_type === 'Document' && item.link.id"
                    :to="$prismic.linkResolver(item.link)"
                >
                    <prismic-image
                        :img="item.image"
                        w="150"
                        h="55"
                    />
                </nuxt-link>
                <a 
                    v-else-if="item.link.link_type === 'Web' && $validateText(item.link.url)"
                    :href="item.link.url" 
                    target="_blank" 
                >
                    <prismic-image
                        :img="item.image"
                        w="150"
                        h="55"
                    />
                </a>
                <prismic-image
                    v-else
                    :img="item.image"
                    w="150"
                    h="55"
                />
            </div>
        </div>
    </Section>
</template>
<script>
export default {
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    computed: {
        items() {
            return this.data.items
                .filter(item => item.image.url);
        }
    }
};
</script>
<style lang='scss' scoped>
    .cooperation-links::v-deep {
        .cooperation-links-header > * {
            text-align: center;
        }
        .cooperation-links-wrapper {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: auto;
            gap: 0.5rem;
            text-align: center;

            .image-container {
                img {
                    max-width: 85%;
                    object-fit: contain;
                }
            }
            
            @include device(pad) {
                grid-template-columns: repeat(3, 1fr);
                row-gap: 2rem;
                
                img {
                    max-width: 100%;
                }
            }
        }
    }
</style>