var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-container"},[_c('div',{staticClass:"table-container__image"},[_c('nuxt-link',{attrs:{"to":_vm.$prismic.linkResolver(_vm.lender)}},[_c('PrismicImage',{attrs:{"img":_vm.lender.data.image,"w":"120","h":"auto"}})],1),_vm._v(" "),_c('ButtonTarget',{attrs:{"text":_vm.$translate('cta_to_application', 'Till ansökan'),"background":"green","link":_vm.lender,"size":"md","placement":"list"}})],1),_vm._v(" "),_c('table',{staticClass:"table-container__table"},[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$translate('lce_amortization_period', 'Amorteringstid')))]),_vm._v(" "),_c('th',[_vm._v(_vm._s(_vm.$translate('lce_monthly_payment', 'Månadsbetalning')))]),_vm._v(" "),_c('th',[_vm._v(_vm._s(_vm.$translate('lce_interest_cost', 'Räntekostnad')))]),_vm._v(" "),_c('th',[_vm._v(_vm._s(_vm.$translate('lce_fees', 'Avgifter')))]),_vm._v(" "),_c('th',[_vm._v(_vm._s(_vm.$translate('lce_payback', 'Återbetala')))]),_vm._v(" "),_c('th',[_vm._v(_vm._s(_vm.$translate('lce_after_interest_deduction', 'Efter ränteavdrag')))])])]),_vm._v(" "),_c('tbody',_vm._l((_vm.periods),function(period,index){return _c('TableCalculationExampleTableRow',{key:index,attrs:{"period":period}})}),1)]),_vm._v(" "),_c('div',{staticClass:"table-container__example"},[_vm._v("\n        "+_vm._s(_vm.$translate(
                'lce_explanation',
                'I exemplet har vi utgått från lägsta möjliga räntan hos {title} ({interest}%) vid ett lån på {amount}. Avikostnaden hos {title} är {adminFee} och uppläggningsavgiften är {setupFee}.'
            )
                .replace(/{amount}/g, _vm.$format.currency(_vm.amount))
                .replace(/{title}/g, _vm.lender.data.title)
                .replace('{interest}', _vm.exampleInterest)
                .replace('{adminFee}', _vm.lenderModel.administrationFeeString)
                .replace('{setupFee}', _vm.lenderModel.setupFeeString))+"\n    ")])])}
var staticRenderFns = []

export { render, staticRenderFns }