<template>
    <tr>
        <th class="fw-bold">{{ title }}</th>
        <td v-for="(item, index) in items" :key="`${index}`">{{ item }}</td>
    </tr>
</template>
<script>
export default {
    props: {
        items: {
            type: Array,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
    },
};
</script>
