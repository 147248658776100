<template>
    <div class="wrapper wrapper-lender">
        <Banner
            :document="document"
            :model="model"
        />

        <Review
            :document="document"
            :model="model"
        />

        <Ucr :data="document.data" />
    </div>
</template>

<script>
import Banner from '@/components/pages/lender/LenderBanner.vue';
import Review from '@/components/pages/lender/LenderReview.vue';
import Ucr from '@/components/slices/ucr/Ucr.vue';
export default {
    components: { Banner, Review, Ucr },
    props: {
        document: {
            type: Object,
            required: true
        }
    },
    computed: {
        model() {
            return new this.$models.CorporateLoan(this.document, this.$store);
        },
        companyInfo() {
            return [
                {
                    name: this.$translate('company_name', 'Företagsnamn'),
                    value: this.model.company.name
                },
                {
                    name: this.$translate('company_org_nr', 'Org.nummer'),
                    value: this.model.company.registrationNumber
                },
                {
                    name: this.$translate('company_address', 'Adress'),
                    value: this.model.company.addres
                },
                {
                    name: this.$translate('company_postal_county', 'Postort'),
                    value: this.model.company.county
                },
                {
                    name: this.$translate('company_phone', 'Telefon'),
                    value: this.model.company.phone
                },
                {
                    name: this.$translate('company_email', 'E-post'),
                    value: `<a href="mailto:${ this.model.company.email }">${ this.model.company.email }</a>`
                }
            ];
        }
    },
    mounted () {
        this.$event.viewCorporateLender(this.document.data.title);
    },
};
</script>
<style lang="scss" scoped>
li::v-deep {
    span {
        a {
            color: $green;
            text-decoration: none;

            &:hover { text-decoration: underline; }
        }
    }
}
</style>
